// 모든 컴포넌트에 적용되어야될 Global 설정을 이 컴포넌트에서 한다. (App.tsx에대한 customizing)

import { useEffect, useState } from "react";
import { Hydrate, QueryClient, QueryClientProvider } from "react-query";
import { AppProps } from "next/app";
import dynamic from "next/dynamic";
import Head from "next/head";
import Router, { useRouter } from "next/router";
import { RecoilRoot } from "recoil";

import Loading from "@sellernote/_shared/src/componentsToMoveToV1/Loading";
import {
  IS_READY_FOR_MSW_TOOL,
  IS_UNDER_LOCAL_DEVELOPMENT,
} from "@sellernote/_shared/src/constants";
import {
  I18N_NAMESPACE_LIST_FOR_DESIGN_SYSTEM,
  initI18nInstanceForDesignSystem,
} from "@sellernote/_shared/src/i18n/i18nForDesignSystem";
import { JotaiDevtools } from "@sellernote/_shared/src/services/jotai";
import { REACT_QUERY_CLIENT_CONFIG } from "@sellernote/_shared/src/services/query";
import { CustomAppProps } from "@sellernote/_shared/src/services/sentry/sentryForNextJS";
import { printBuildInfo } from "@sellernote/_shared/src/utils/common/etc";
import GlobalStyle from "@sellernote/_sds-v1/src/styles/global";
import {
  makeGetInitialPropsFunctionOnAppRoot,
  wrapper,
} from "@sellernote/shipda-kr/src/store";

import { appWithTranslation, Trans, useTranslation } from "../utils/i18n";

const MSWTool = dynamic(() => import("@sellernote/_shared/src/mocks/MSWTool"));
const RecoilDevTools = dynamic(async () => {
  const { RecoilDevTools } = await import("recoil-toolkit");
  return RecoilDevTools;
});
const ReactQueryDevtools = dynamic(async () => {
  const { ReactQueryDevtools } = await import("react-query/devtools");
  return ReactQueryDevtools;
});

function App({ Component, pageProps, err }: CustomAppProps<AppProps>) {
  useEffect(() => {
    printBuildInfo();
  }, []);

  const router = useRouter();

  const [isPageLoading, setIsPageLoading] = useState(false);

  const { t } = useTranslation(I18N_NAMESPACE_LIST_FOR_DESIGN_SYSTEM);

  initI18nInstanceForDesignSystem({ Trans, t });

  /**
   * 라우팅시 이전 path, 현재 path를 session storage에 저장
   * @see useWarnUnsavedChanges (useWarnUnsavedChanges 훅에서 뒤로가기 동작에 사용)
   */
  useEffect(() => {
    return () => {
      const sessionStorage = globalThis.sessionStorage;

      if (!sessionStorage) return;

      sessionStorage.setItem("previousPath", router.asPath || "/");
    };
  }, [router]);

  const [queryClient] = useState(
    () => new QueryClient(REACT_QUERY_CLIENT_CONFIG)
  );

  useEffect(() => {
    Router.events.on("beforeHistoryChange", handleBeforeHistoryChange);
    Router.events.on("routeChangeStart", handleRouteChangeStart);
    Router.events.on("routeChangeComplete", handleRouteChangeComplete);
    Router.events.on("routeChangeError", handleRouteChangeError);

    return () => {
      Router.events.off("beforeHistoryChange", handleBeforeHistoryChange);
      Router.events.off("routeChangeStart", handleRouteChangeStart);
      Router.events.off("routeChangeComplete", handleRouteChangeComplete);
      Router.events.off("routeChangeError", handleRouteChangeError);
    };
  }, []);

  return (
    <>
      <QueryClientProvider client={queryClient}>
        {IS_UNDER_LOCAL_DEVELOPMENT && (
          <ReactQueryDevtools initialIsOpen={false} />
        )}

        {/* TODO: 아직 미구현된 기능이라 주석처리 & 개발 끝나고 더 적절한 위치로 변경 - 현재 테스트용으로 임시 배치함 */}
        {/* {IS_UNDER_LOCAL_DEVELOPMENT && <LanguageChangeButton />} */}

        <Head>
          <link
            rel="stylesheet"
            href="/assets/fonts/noto-sans-kr/NotoSansKR.css"
          />
          <link rel="stylesheet" href="/assets/fonts/Roboto/Roboto.css" />
          <link
            rel="stylesheet"
            href="/assets/fonts/SpoqaHanSans/SpoqaHanSans.css"
          />
          <link
            rel="stylesheet"
            href="/assets/fonts/Nanumsquare/Nanumsquare.css"
          />
          <link
            rel="stylesheet"
            href="/assets/fonts/Pretendard/Pretendard.css"
          />
        </Head>

        <GlobalStyle />

        <Hydrate state={pageProps.dehydratedState}>
          <RecoilRoot>
            {IS_UNDER_LOCAL_DEVELOPMENT && (
              <RecoilDevTools forceSerialize={false} />
            )}

            <JotaiDevtools>
              <Component {...pageProps} err={err} />
            </JotaiDevtools>
          </RecoilRoot>
        </Hydrate>

        {IS_READY_FOR_MSW_TOOL && <MSWTool type="web" />}

        <Loading active={isPageLoading} label={t("common:loading")} />
      </QueryClientProvider>
    </>
  );

  function handleBeforeHistoryChange() {
    setIsPageLoading(true);
  }

  function handleRouteChangeStart() {
    setIsPageLoading(true);
  }

  function handleRouteChangeComplete(url: string) {
    setIsPageLoading(false);

    // 스케줄 조회에서는 스크롤을 유지하도록 임시 처리
    if (!url.includes("/forwarding/schedule")) {
      window.scrollTo(0, 0);
    }
  }

  function handleRouteChangeError(err: any) {
    setIsPageLoading(true);
  }
}

export default wrapper.withRedux(appWithTranslation(App));

export const getInitialProps = makeGetInitialPropsFunctionOnAppRoot();
